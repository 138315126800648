<template>
  <div id="app" class="app-container">
    <start v-if="step === steps.START" />
    <select-character v-if="step === steps.SELECT" />
    <questions v-if="step === steps.QUESTION" />
    <div class="footer">
      <div class="container">
        «Кредит наличными» по ставке 5,9% годовых, сумма кредита от 30 000 до3 000 000руб., срок кредита 36, 48, 60, 72, 84 мес. Ставка 5.9% годовых действует при условии платного подключения к Программе «Гарантия низкой ставки», комиссия за подключение составляет от 1,5% до 60,0% от суммы кредита  (размер указывается в твердой сумме в рублях в Договоре в зависимости от срока кредита и размера процентной ставки, которая могла быть установлена по Договору при его одобрении). В случае отказа от Программы в течение первых 14 дней процентная ставка по кредиту будет увеличена и составит от 17,1% до 22,8% годовых (размер указывается в Договоре в зависимости от размера процентной ставки, которая могла быть установлена по Договору при его одобрении, и суммы кредита). Банк вправе отказать в выдаче кредита или предложить иной. ООО «ХКФ Банк», Ген.лицензия ЦБ РФ №316.
      </div>
    </div>
  </div>
</template>

<script>
import Start from "@/views/Start";
import SelectCharacter from "@/views/SelectCharacter";
import Questions from "@/views/Questions";
const imagesObject = [
  "/img/v1/v1.png",
  "/img/v1/q/v1-q1.png",
  "/img/v1/q/v1-q1-a.png",
  "/img/v1/q/v1-q2.png",
  "/img/v1/q/v1-q2-a.png",
  "/img/v1/q/v1-q3.png",
  "/img/v1/q/v1-q3-a.png",
  "/img/v1/q/v1-q3-alt.png",
  "/img/v1/q/v1-q3-alt-a.png",
  "/img/v1/q/v1-q4.png",
  "/img/v1/q/v1-q4-a.png",
  "/img/v1/q/v1-q4-alt.png",
  "/img/v1/q/v1-q4-alt-a.png",
  "/img/v1/q/v1-q5.png",
  "/img/v1/q/v1-q5-a.png",
  "/img/v1/q/v1-q6.png",
  "/img/v1/q/v1-q6-a.png",
  "/img/v1/q/v1-q7.png",
  "/img/v1/q/v1-q7-a.png",
  "/img/v1/v1-final.png",

  "/img/v2/v2.png",
  "/img/v2/q/v2-q1.png",
  "/img/v2/q/v2-q2.png",
  "/img/v2/q/v2-q3.png",
  "/img/v2/q/v2-q4.png",
  "/img/v2/q/v2-q5.png",
  "/img/v2/q/v2-q6.png",
  "/img/v2/q/v2-q7.png",
  "/img/v2/v2-final.png",

  "/img/v3/v3.png",
  "/img/v3/q/v3-q1.png",
  "/img/v3/q/v3-q2.png",
  "/img/v3/q/v3-q3.png",
  "/img/v3/q/v3-q4.png",
  "/img/v3/q/v3-q4-alt.png",
  "/img/v3/q/v3-q5.png",
  "/img/v3/q/v3-q5-alt.png",
  "/img/v3/q/v3-q6.png",
  "/img/v3/q/v3-q7.png",
  "/img/v3/v3-final.png",

  "/img/v4/v4.png",
  "/img/v4/q/v4-q1.png",
  "/img/v4/q/v4-q2.png",
  "/img/v4/q/v4-q3.png",
  "/img/v4/q/v4-q4.png",
  "/img/v4/q/v4-q4-alt.png",
  "/img/v4/q/v4-q5.png",
  "/img/v4/q/v4-q6.png",
  "/img/v4/q/v4-q6-alt.png",
  "/img/v4/q/v4-q7.png",
  "/img/v4/v4-final.png",
];

export default {
  name: "App",
  components: {
    Start,
    SelectCharacter,
    Questions,
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
    steps() {
      return this.$store.state.steps;
    },
  },
};
</script>
